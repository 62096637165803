import ArticleDetail from "../pages/articles/ArticleDetail";
import ArticlesPage from "../pages/articles/ArticlesPage";
import EventDetail from "../pages/events/EventDetail";
import EventsPage from "../pages/events/EventsPage";
import MainPage from "../pages/main/MainPage";
import ProjectDetail from "../pages/projects/ProjectDetail";
import ProjectsPage from "../pages/projects/ProjectsPage";
import SamplePage from "../pages/sample/SamplePage";
import ServiceDetail from "../pages/services/ServiceDetail";
import ServicesPage from "../pages/services/ServicesPage";
import { ARTICLE_PAGE_ROUTE, MAIN_PAGE_ROUTE, PROJECTS_PAGE_ROUTE, ARTICLE_DETAIL_PAGE_ROUTE, ERROR_PAGE_ROUTE, EVENT_DETAIL_PAGE_ROUTE, EVENTS_PAGE_ROUTE, PROJECTS_DETAIL_PAGE_ROUTE, SERVICES_PAGE_ROUTE, SERVICES_DETAIL_PAGE_ROUTE, SAMPLE_PAGE_SOUTE} from "./Constants";
export const publicRoutes = [
    {
        path: '/',
        Component: MainPage
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: ARTICLE_PAGE_ROUTE,
        Component: ArticlesPage
    },
    {
        path: ARTICLE_DETAIL_PAGE_ROUTE,
        Component: ArticleDetail
    },
    {
        path: PROJECTS_PAGE_ROUTE,
        Component: ProjectsPage
    },
    {
        path: PROJECTS_DETAIL_PAGE_ROUTE,
        Component: ProjectDetail
    },
    {
        path: SERVICES_PAGE_ROUTE,
        Component: ServicesPage
    },
    {
        path: SERVICES_DETAIL_PAGE_ROUTE,
        Component: ServiceDetail
    },
    {
        path: EVENTS_PAGE_ROUTE,
        Component: EventsPage
    },
    {
        path: EVENT_DETAIL_PAGE_ROUTE,
        Component: EventDetail
    },
    {
        path: SAMPLE_PAGE_SOUTE,
        Component: SamplePage
    }
]