import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import PartnersServices from '../../services/general/partnersServices'

const Partners = ({elem}) => {
    const {
        items,
        loading,
        setFilterData
    } = useFetchItems(PartnersServices.index)
  return (
	<div className="section pdy-0">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-8 col-lg-5 text-center">
					<div className="section-head section-md">
						<h5 className="heading-xs no-line tc-primary">{elem.subtitle}</h5>
						<h2 className="fz-1">{elem.title}</h2>
					</div>
				</div>
			</div>
			<div className="row justify-content-center gutter-vr-30px">
                {items?.items?.map((card, index) =>
                    <div className="col-sm-3 col-4" key={index}>
                        <div className="logo-item">
                            <a href={card.link} target='_blank'>
                                <img src={'https://solution-0004.panama.kz' + card.image} alt=""/>
                            </a>
                        </div>
                    </div>
                )}
			</div>
		</div>
	</div>
  )
}

export default Partners