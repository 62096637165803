import React from 'react'

const StepCard = ({card}) => {
  return (
    <div className="col-xl-3 col-sm-6 text-center">
        <div className="feature feature-alt feature-alt-s2">
            <div className="feature-icon">
                <img src={'https://solution-0004.panama.kz' + card.image} alt="" />
            </div>
            <div className="feature-content">
                <h4>{card.title}</h4>
                <p dangerouslySetInnerHTML={{__html: card.description}}></p>
            </div>
        </div>
    </div>
  )
}

export default StepCard