import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang'

const FormContent = ({elem}) => {
    const {localizationsItems} = useLangContext();

  return (
	<div className="section section-x tc-bunker pt-0">
		<div className="container">
			<div className="row justify-content-center gutter-vr-30px">
				<div className="col-lg-8 text-center">
					<div className="section-head">
						<h5 className="heading-xs tc-primary">{elem.subtitle}</h5>
						<h2 className="fz-1">{elem.title}</h2>
					</div>
				</div>
			</div>
			<div className="row gutter-vr-30px align-items-center">
                {elem.items &&
				<div className="col-lg-4 order-lg-last">
					{elem.items?.map((card, index) =>
                        <div key={index} className="contact-text contact-text-s4 bg-bunker-alt box-pad box-pad-s2" dangerouslySetInnerHTML={{__html: card.description}}></div>
                    )}
				</div>}
				<div className="col-lg-8">
					<form className="genox-form form-dark">
						<div className="row">
                            {elem.elements?.map((card, index) =>
                                <div className="form-field col-md-6" key={index}>
                                    <input type="text" placeholder={card.placeholder} className="input bdr-b required"/>
                                </div>
                            )}
						</div>
						<div className="row">
							<div className="form-field col-md-12">
								<button type="submit" className="btn">{localizationsItems?.form_contact_button}</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
  )
}

export default FormContent