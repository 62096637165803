import React, { useState } from 'react'
import ArticleCard from '../simple/ArticleCard'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import RecentNews from '../simple/RecentNews'
import NewsCategories from '../simple/NewsCategories'
import Pagination from './Pagination'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const ArticlesContainer = () => {
    let query = new URLSearchParams(useLocation().search);
    const [term, setTerm] = useState('');
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)

    useEffect(() => {
        if(query.get('term')){
            setFilterData(query.toString())
            setTerm(query.get('term'))
        }
    }, [query.get('term')])

    const searchOn = () => {
        setFilterData(`term=${term}`);
        navigate(`/${lang}/articles?term=${term}`);
    }

  return (
	<div className="section blog section-x tc-bunker pt-0">
		<div className="container">
			<div className="row gutter-vr-30px">
                <div className="col-md-8">
                    {items?.items?.map((card, index) =>
                        <ArticleCard card={card} key={index}/>
                    )}
                </div>
				<div className="col-md-8 order-md-last">
					<div className="button-area pagination-area">
                        <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
					</div>
				</div>
				<div className="col-md-4 pl-lg-4">
					<div className="sidebar">
						<div className="wgs wgs-sidebar wgs-search">
							<div className="wgs-content">
								<div className="form-group">
									<input value={term} onChange={(e) => setTerm(e.target.value)} type="text" className="form-input form-input-fill bg-bunker-alt"  placeholder={localizationsItems?.search}/>
									<button onClick={() => searchOn()} className="search-btn"><i className="ti ti-search" aria-hidden="true"></i></button>
								</div>
							</div>
						</div>
                        <RecentNews/>
                        <NewsCategories/>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default ArticlesContainer