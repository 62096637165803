import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import ArticleServices from '../../services/general/articleServices';
import Utils from '../../services/utils';
import { NavLink } from 'react-router-dom';

const RelatedPosts = () => {
    const {lang, localizationsItems} = useLangContext();

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)

  return (
    <div className="wgs">
        <div className="section-head">
            <h3 className="wgs-heading mb-10">{localizationsItems?.related_posts}</h3>
        </div>
        <div className="row gutter-vr-30px">
            {items?.items?.map((card, index) => 
                <div className="col-12 col-lg-6" key={index}>
                    <div className="post post-full post-full-s2 post-v2">
                        <div className="post-thumb">
                            <NavLink to={`/${lang}/articles/${card.slug}`}>
                                <img src={'https://solution-0004.panama.kz' + card.image} alt=""/>
                            </NavLink>
                        </div>
                        <div className="post-entry bg-bunker-alt d-sm-flex d-block align-items-start">
                            <div className="post-date">
                                <p>{Utils.generateDate2(card.date)?.mon} <strong>{Utils.generateDate2(card.date)?.day}</strong></p>
                            </div>
                            <div className="post-content">
                                <h4><NavLink to={`/${lang}/articles/${card.slug}`}>{card.title}</NavLink></h4>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </div>
  )
}

export default RelatedPosts