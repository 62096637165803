import React from 'react'

const PageTop = ({item}) => {
  return (
    <div className="banner banner banner-inner banner-inner-md tc-light">
        <div className="banner-block has-bg-image">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-xl-8 text-center">
                        <div className="banner-content pm-0">
                            <h1 className="banner-title banner-inner-title-s3">{item?.title}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-image overlay-gradient overlay-fall bg-image-loaded" style={{backgroundImage: `url(https://solution-0004.panama.kz${item?.detail_image})`}}></div>
        </div>
    </div>
  )
}

export default PageTop