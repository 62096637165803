import React from 'react'
import StepCard from '../simple/StepCard'

const Steps = ({elem}) => {
  return (
	<div className="section tc-bunker section-xx pt-0 has-bg-image">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-8 text-center">
					<div className="section-head section-md">
						<h5 className="heading-xs tc-primary">{elem.subtitle}</h5>
						<h2 className="fz-1 mb-0">{elem.title}</h2>
					</div>
				</div>
			</div>
			<div className="row gutter-vr-40px">
                {elem.elements?.map((card, index) =>
                    <StepCard card={card} key={index}/>
                )}
			</div>
		</div>
		<div className="bg-image overlay-gradient-full overlay-fall bg-image-loaded" style={{backgroundImage: `url(https://solution-0004.panama.kz${elem.steps_image})`}}>
		</div>
	</div>
  )
}

export default Steps