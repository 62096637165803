import React from 'react'
import CustomCollapse from '../simple/CustomCollapse'

const Faq = ({elem}) => {
  return (
	<section className="section section-x section-xbl tc-bunker pt-0">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-lg-8 text-center">
					<div className="section-head section-md section-head-s4">
						<h5 className="heading-xs tc-primary">{elem.subtitle}</h5>
						<h2 className="fz-1">{elem.title}</h2>
					</div>
				</div>
			</div>
			<div className="row justify-content-between gutter-vr-30px">
				<div className="col-lg-12">
					<div className="accordion accordion-bdr" id="faq">
						{elem.elements?.map((card, index) =>
                            <CustomCollapse elem={card} key={index}/>
                        )}
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default Faq