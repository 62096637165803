import React, { useEffect } from 'react'
import PageTop from '../../components/simple/PageTop'
import ArticleDetailContainer from '../../components/complex/ArticleDetailContainer'
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import ArticleServices from '../../services/general/articleServices';
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import About from '../../components/complex/About';
import Steps from '../../components/complex/Steps';
import Portfolio from '../../components/complex/Portfolio';
import Reviews from '../../components/complex/Reviews';
import Partners from '../../components/complex/Partners';
import Team from '../../components/complex/Team';
import Pricing from '../../components/complex/Pricing';
import Faq from '../../components/complex/Faq';
import FormContent from '../../components/complex/FormContent';
import AdvertisingBanner from '../../components/complex/AdvertisingBanner';
import Gallery from '../../components/complex/Gallery';

const ArticleDetail = () => {
    const {slug} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    const {info, loading} = useFetchInfo(ArticleServices.view, slug, null, lang);

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/articles/${slug}`)
            }
        }
    }, [lang])

  return (
    <>
        {info ?
            <div>
                <PageTop item={info}/>
                <ArticleDetailContainer info={info}/>
                {info.elements?.map((elem, index) =>
                    <div key={index}>
                        {
                            elem.type === "about" ?
                                <About elem={elem}/>
                            :
                            elem.type === "steps" ?
                                <Steps elem={elem}/>
                            :
                            elem.type === "projects" ?
                                <Portfolio elem={elem}/>
                            :
                            elem.type === "reviews" ?
                                <Reviews elem={elem}/>
                            :
                            elem.type === "partners" ?
                                <Partners elem={elem}/>
                            :
                            elem.type === "workers" ?
                                <Team elem={elem}/>
                            :
                            elem.type === "packages" ?
                                <Pricing elem={elem}/>
                            :
                            elem.type === "faq" ?
                                <Faq elem={elem}/>
                            :
                            elem.type === "form" ?
                                <FormContent elem={elem}/>
                            :
                            elem.type === "advertising-banner-2" ?
                                <AdvertisingBanner elem={elem}/>
                            :
                            elem.type === "gallery" ?
                                <Gallery elem={elem}/>
                            :
                            <></>
                        }
                    </div>
                )}
            </div>
            :
            <div className="preloader preloader-dark preloader-jackson no-split"><span className="spinner spinner-alt"></span></div>
        }
    </>
  )
}

export default ArticleDetail