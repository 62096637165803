import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const WorkCard = ({card}) => {
    const {lang} = useLangContext();
  return (
    <div className="filtr-item" data-category="1">
        <NavLink to={`/${lang}/projects/${card.slug}`} className="project-item">
            <div className="project-image">
                <img src={'https://solution-0004.panama.kz' + card.image} alt=""/>
            </div>
            <div className="project-over">
                <div className="project-content">
                    <h4>{card.title}</h4>
                    <p>{card.subtitle}</p>
                </div>	
            </div>
        </NavLink>
    </div>
  )
}

export default WorkCard