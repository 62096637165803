import React from 'react'
import RecentEvents from '../simple/RecentEvents'
import Utils from '../../services/utils'

const EventDetailContainer = ({info}) => {
  return (
	<div className="section blog section-xx tc-bunker pt-0">
		<div className="container">
			<div className="row gutter-vr-40px">
				<div className="col-md-8">
					<div className="post post-full post-full-s2 post-details">
						<div className="post-thumb">
							<img src={'https://solution-0004.panama.kz' + info.image} alt=""/>
						</div>
						<div className="post-entry d-sm-flex d-block align-items-start">
							<div className="content-left d-flex d-sm-block">
								<div className="post-date">
									<p>{Utils.generateDate2(info.date)?.mon} <strong>{Utils.generateDate2(info.date)?.day}</strong></p>
								</div>
							</div>
							<div className="post-content post-content-wd">
								{info.subtitle && <div className="post-meta d-block d-lg-flex align-items-center">
									{info.subtitle}
								</div>}
								<h3>{info.title}</h3>
								<div className="content"
                                dangerouslySetInnerHTML={{__html: info.description}}>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-4 pl-lg-4">
					<div className="sidebar">
						<RecentEvents/>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default EventDetailContainer