import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const ServiceCard = ({card}) => {
    const {lang} = useLangContext();

  return (
    <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="post post-service post-alt bg-bunker-alt">
            <div className="post-thumb">
                <NavLink to={`/${lang}/services/${card.slug}`}>
                    <img src={'https://solution-0004.panama.kz' + card.image} alt="post"/>
                </NavLink>
            </div>
            <div className="post-content">
                <p className="post-tag">{card.subtitle}</p>
                <h4><NavLink to={`/${lang}/services/${card.slug}`}>{card.title}</NavLink></h4>
            </div>
        </div>
    </div>
  )
}

export default ServiceCard