import React from 'react'

const ReviewCard = ({card}) => {
  return (
    <div className="tes-block ">
        <div className="tes-content tes-content-s2 tes-bg">
            <p dangerouslySetInnerHTML={{__html: card.description}}></p>
        </div>
        <div className="tes-author d-flex align-items-center">
            <div className="author-image">
                <img src={'https://solution-0004.panama.kz' + card.image} alt="Author Image"/>
            </div>
            <div className="author-con author-con-s2">
                <h6 className="author-name t-u">{card.title}</h6>
                <p className="tc-light">{card.subtitle}</p>
            </div>
        </div>
    </div>
  )
}

export default ReviewCard