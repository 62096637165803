import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';

const ProjectCard = ({card}) => {
    const {lang} = useLangContext();

  return (
    <div className="col-md-6 col-sm-10 filtr-item" >
        <NavLink to={`/${lang}/projects/${card.slug}`} className="project-item">
            <div className="project-image">
                <img src={'https://solution-0004.panama.kz' + card.image} alt=""/>
            </div>
            <div className="project-over">
                <div className="project-content">
                    <h4>{card.title}</h4>
                    <p>{card.subtitle}</p>
                </div>	
            </div>
        </NavLink>
    </div>
  )
}

export default ProjectCard