import React from 'react'
import NewsCategories from '../simple/NewsCategories'
import RecentNews from '../simple/RecentNews'
import RelatedPosts from '../simple/RelatedPosts'
import Utils from '../../services/utils'

const ArticleDetailContainer = ({info}) => {
  return (
	<div className="section blog section-xx tc-bunker pt-0">
		<div className="container">
			<div className="row gutter-vr-40px">
				<div className="col-md-8">
					<div className="post post-full post-full-s2 post-details">
						<div className="post-thumb">
							<img src={'https://solution-0004.panama.kz' + info.image} alt=""/>
						</div>
						<div className="post-entry d-sm-flex d-block align-items-start">
							<div className="content-left d-flex d-sm-block">
								<div className="post-date">
									<p>{Utils.generateDate2(info.date)?.mon} <strong>{Utils.generateDate2(info.date)?.day}</strong></p>
								</div>
							</div>
							<div className="post-content post-content-wd">
								{/*<div className="post-meta d-block d-lg-flex align-items-center">
									<div className="post-tag d-flex">
										<ul className="post-cat">
											<li><a href="#"><em className="icon ti-bookmark"></em><span> Design , UI / UX</span></a></li>
										</ul>
									</div>
                                </div>*/}
								<h3>{info.title}</h3>
								<div className="content"
                                dangerouslySetInnerHTML={{__html: info.description}}>
								</div>
							</div>
						</div>
					</div>
                    <RelatedPosts/>
				</div>
				<div className="col-md-4 pl-lg-4">
					<div className="sidebar">
						<div className="wgs wgs-sidebar wgs-search bg-bunker-alt">
							<div className="wgs-content">
								<div className="form-group">
									<input type="text" className="form-input form-input-fill bg-bunker-alt"  placeholder="Search..."/>
									<button className="search-btn"><i className="ti ti-search" aria-hidden="true"></i></button>
								</div>
							</div>
						</div>
						<RecentNews/>
						<NewsCategories/>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default ArticleDetailContainer