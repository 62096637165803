import React from 'react'
import { Modal } from 'react-bootstrap';

const WorkerModal = ({show, setShow, card}) => {
    const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered  size='lg' >
        <Modal.Body className='padding-0'>
            <div id="team-popup-1" className="team-popup bg-bunker-alt">
                <div title="Close" className="mfp-close" onClick={handleClose}>×</div>
                <div className="row align-items-center no-gutters">
                    <div className="col-md-6">
                        <div className="team-photo">
                            <img src={'https://solution-0004.panama.kz' + card.image} alt="team"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="team-popup-info">
                            <h3 className="team-name title title-lg pt-4">{card.name}</h3>
                            <p className="team-position">{card.position}</p>
                            <p dangerouslySetInnerHTML={{__html: card.description}}></p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default WorkerModal