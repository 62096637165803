import React from 'react'

const NewsCategories = () => {
  return (
    <div className="wgs wgs-sidebar bg-bunker-alt  wgs-category">
        <h3 className="wgs-heading">Categories</h3>
        <div className="wgs-content">
            <ul className="wgs-menu">
                <li><a href="#">Business  <span>(10)</span></a></li>
                <li><a href="#">Technology  <span>(01)</span></a></li>
                <li><a href="#">Development  <span>(17)</span></a></li>
                <li><a href="#">Marketing   <span>(40)</span></a></li>
            </ul>
        </div>
    </div>
  )
}

export default NewsCategories