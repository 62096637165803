import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'
import Utils from '../../services/utils';

const ArticleCard = ({card}) => {
    const {lang} = useLangContext();
  return (
        <div className="post post-full post-full-s2">
            <div className="post-thumb">
                <NavLink to={`/${lang}/articles/${card.slug}`}>
                    <img src={'https://solution-0004.panama.kz' + card.image} alt=""/>
                </NavLink>
            </div>
            <div className="post-entry d-sm-flex d-block align-items-start">
                <div className="post-date">
                    <p>{Utils.generateDate2(card.date)?.mon} <strong>{Utils.generateDate2(card.date)?.day}</strong></p>
                </div>
                <div className="post-content">
                    <h3><NavLink to={`/${lang}/articles/${card.slug}`}>{card.title}</NavLink></h3>
                    <div className="content" dangerouslySetInnerHTML={{__html: card.description}}>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ArticleCard