import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

const MenuItem = ({card, setMobileShow}) => {
    const [open, setOpen] = useState();

  return (
    <>
        {card.items ?
            <li className={open ? "menu-item has-sub open-nav" : "menu-item has-sub"}>
                <NavLink onClick={() => setOpen(!open)} className="menu-link nav-link menu-toggle" target={card.type} to={card.value}>{card.title}</NavLink>
                <ul className="menu-sub menu-drop">
                    {card.items.map((it, index) =>
                        <li className="menu-item" key={index} onClick={() => setMobileShow(false)}><NavLink className="menu-link nav-link" target={it.type} to={it.value}>{it.title}</NavLink></li>
                    )}
                </ul>
            </li>
        :
            <li className="menu-item" onClick={() => setMobileShow(false)}>
                <NavLink className="menu-link nav-link menu-toggle" target={card.type} to={card.value}>{card.title}</NavLink>
            </li>
        }       
    </>
  )
}

export default MenuItem