import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HeaderSearch = ({searchShow, setSearchShow}) => {
    const {lang, localizationsItems} = useLangContext();
    const [term, setTerm] = useState('');
    const navigate = useNavigate();

    const handleClose = (e) => {
        e.stopPropagation();
        setSearchShow(false);
    }

    const handlePropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const searchOn = () => {
        navigate(`/${lang}/articles?term=${term}`);
        setSearchShow(false)
    }

  return (
    <div className={searchShow ? "header-search search-show" : "header-search"} onClick={(e) => handleClose(e)}> 
        <div className="search-form" onClick={e => handlePropagation(e)}>
            <div className="search-group">
                <input value={term} onChange={(e) => setTerm(e.target.value)} type="text" className="input-search" placeholder={localizationsItems?.search}/>
                <button className="search-submit" type="submit" onClick={() => searchOn()}><i className="icon ti-search"></i></button>
            </div>
        </div>
    </div>
  )
}

export default HeaderSearch