import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import useFetchItems from '../hooks/useFetchItems';
import AppServices from '../services/general/appServices';
import { ERROR_PAGE_ROUTE, MAIN_PAGE_ROUTE } from './Constants';
import { publicRoutes } from './Routes';
import Header from '../components/complex/Header';
import Footer from '../components/complex/Footer';

const AppRouter = () => {
  const [items, setItems] = useState();

  const fetchdata = async () => {
    const res = await AppServices.settings();

    if(res.statusCode === 200){
      setItems(res.content)
    }
  }
  useEffect(() => {
    fetchdata();
  }, [])

  useEffect(() => {
    if(items){
        let color = items.find(it => it.type === "color-primary")?.value;
        let bodyColor = items.find(it => it.type === "body-color")?.value;
        let cardColor = items.find(it => it.type === "card-color")?.value;
        let footerColor = items.find(it => it.type === "footer-color")?.value;
        let headerColor = items.find(it => it.type === "header-color")?.value;
        let fontColor = items.find(it => it.type === "font-color")?.value;
        let secondaryFontColor = items.find(it => it.type === "secondary-font-color")?.value;
        let headerFontColor = items.find(it => it.type === "header-font-color")?.value;

        let mainFont = items.find(it => it.type === "font-primary")?.value;
        let secondatyFont = items.find(it => it.type === "font-secondary")?.value;

        let head = items.find(it => it.type === "head")?.value;
        let bottom = items.find(it => it.type === "bottom")?.value;
        let header = items.find(it => it.type === "header")?.value;

        if(head){
            head.forEach(element => {
                const divFragment =  document.createRange().createContextualFragment(element)
                document.head.append(divFragment);
            });
        }

        if(header){
            header.forEach(element => {
                const divFragment =  document.createRange().createContextualFragment(element)
                document.body.append(divFragment);
            });
        }

        if(bottom){
            bottom.forEach(element => {
                const divFragment =  document.createRange().createContextualFragment(element)
                document.body.prepend(divFragment);
            });
        }

        color && document.documentElement.style.setProperty('--mainColor', color);
        bodyColor && document.documentElement.style.setProperty('--bodyColor', bodyColor);
        cardColor && document.documentElement.style.setProperty('--cardColor', cardColor);
        footerColor && document.documentElement.style.setProperty('--footerColor', footerColor);
        headerColor && document.documentElement.style.setProperty('--headerColor', headerColor);
        fontColor && document.documentElement.style.setProperty('--fontColor', fontColor);
        secondaryFontColor && document.documentElement.style.setProperty('--secondaryFontColor', secondaryFontColor);
        headerFontColor && document.documentElement.style.setProperty('--headerFontColor', headerFontColor);

        if(mainFont){
            let elements = mainFont.elements
            for(let i =0; i<elements.length; i++){
            const font_name = new FontFace(mainFont.font_name, `url(${elements[i].link})`, {
                style: elements[i].type,
                weight: elements[i].value
            });
            document.fonts.add(font_name);
            }
            document.documentElement.style.setProperty('--fontFamily', `${mainFont.font_name}, sans-serif`)
        }
        if(secondatyFont){
            let elements = secondatyFont.elements
            for(let i =0; i<elements.length; i++){
            const font_name = new FontFace(secondatyFont.font_name, `url(${elements[i].link})`, {
                style: elements[i].type,
                weight: elements[i].value
            });
            document.fonts.add(font_name);
            }
            document.documentElement.style.setProperty('--fontFamily2', `${secondatyFont.font_name}, sans-serif`)
        }
    }
  }, [items])

  return (
    <Routes>
        {publicRoutes.map(({path, Component}) => 
          <Route key={path} path={path} element={
            <>
                <Header item={items}/>
                <Component></Component>
                <Footer item={items} />
            </>
          } exact/>
        )}
        <Route
            path="*"
            exact
            element={<Navigate to={ERROR_PAGE_ROUTE}/>}
        />

    </Routes>
  )
}

export default AppRouter