import React, { useEffect, useState } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { useLangContext } from '../../i18n/ProvideLang';

const MainBanner = ({elem}) => {
    const [banner, setBanner] = useState();
    const [socials, setSocials] = useState();
    const {localizationsItems} = useLangContext();

    useEffect(() => {
        if(elem){
            setBanner(elem.elements?.find(it => it.type === "banner_image")?.image)
        }
    }, [elem])

    const {
        items:settings
    } = useFetchItems(AppServices.settings)

    useEffect(() => {
        if(settings){
            setSocials(settings.find(it => it.type === "socials")?.value)
        }
    }, [settings])

  return (
    <div className="banner banner-s3 tc-light">
        <div className="banner-block has-bg-image">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-xl-8 text-center">
                        <div className="banner-content">
                            <h1 className="banner-heading size-sm">{elem.title}</h1>
                            <p className="lead">{elem.subtitle}</p>
                            {socials &&
                            <ul className="social social-style-icon">
                                {socials.map((sc, index) =>
                                    <li key={index}>
                                        <a href="" className={
                                            sc.type === "facebook" ? "fab fa-facebook-f" :
                                            sc.type === "twitter" ? "fab fa-twitter" :
                                            sc.type === "pinterest" ? "fab fa-pinterest" :
                                            sc.type === "instagram" ? "fab fa-instagram" :
                                            sc.type === "whatsapp" ? "fab fa-whatsapp" :
                                            sc.type === "vk" ? "fab fa-vk" :
                                            "fab fa-facebook-f"
                                        }></a>
                                    </li>
                                )}
                            </ul>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-image overlay-gradient overlay-fall bg-image-loaded" style={{backgroundImage: `url(https://solution-0004.panama.kz${banner})`}}>
            </div>
        </div>
        <div className="banner-btn text-center">
            <div className="scroll scroll-s2">
                <h6 className="t-u">{localizationsItems?.scroll_down}</h6>
                <a href="#about" className="menu-link">
                    <span className="scroll-icon"></span>
                </a>
            </div>
        </div>
    </div>
  )
}

export default MainBanner