import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ReviewCard from '../simple/ReviewCard';

const Reviews = ({elem}) => {
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        speed: 1200,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              }
            },
        ]
    };
  return (
	<div className="section section-xx has-bg-image">
		<div className="container">
			<div className="row">
				<div className="col-md-4 col-lg-3">
					<div className="section-head res-m-btm mrm-4">
						<h5 className="heading-xs tc-primary">{elem.subtitle}</h5>
						<h2 className="fz-1">{elem.title}</h2>
					</div>
				</div>
				<div className="col-lg-6 offset-lg-1 col-md-8">
					<div className="section-head">
						<p className="lead" dangerouslySetInnerHTML={{__html: elem.description}}></p>
					</div>
				</div>
			</div>
			<div className="tes tes-s3 tes-s3-alt mt-5">
				<Slider {...settings} className="has-carousel">
                    {elem.elements?.map((card, index) =>
                        <ReviewCard card={card} key={index}/>
                    )}
				</Slider>
			</div>
		</div>
		<div className="bg-image overlay-gradient-full overlay-fall bg-image-loaded" style={{backgroundImage: `url(https://solution-0004.panama.kz${elem.review_image})`}}></div>
	</div>
  )
}

export default Reviews