import React, { useEffect, useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { NavLink } from 'react-router-dom';

const Footer = ({item}) => {
    const [socials, setSocials] = useState();
    const [logo, setLogo] = useState();
    const {lang, localizationsItems} = useLangContext();

    const { 
        items,
    } = useFetchItems(AppServices.menu, "bottom-menu");

    useEffect(() => {
        if(item){
            setLogo(item.find(it => it.type === "logo")?.value)
            setSocials(item.find(it => it.type === "socials")?.value)
        }
    }, [item])
  return (
	<footer className="section footer tc-bunker footer-s3">
		<div className="container">
			<div className="row gutter-vr-30px justify-content-sm-between justify-content-center">
				<div className="col-lg-3 col-md-4 text-center text-md-left">
					<div className="wgs">
						<div className="wgs-content">
							<div className="wgs-logo">
								<NavLink to={`/${lang}`}>
									<img src={'https://solution-0004.panama.kz' + logo} alt="logo"/>
								</NavLink>
							</div>
							<p>&copy; Panama.kz</p>
						</div>
					</div>
				</div>
				{socials && <div className="col-lg-6 col-md-4 text-center">
					<div className="wgs mtm-5">
						<div className="wgs-content">
							<h4 className="mb-10">{localizationsItems?.socials}</h4>
							<ul className="social social-style-icon social-s3">
                                {socials.map((sc, index) =>
								    <li key={index}>
                                        <a href={sc.value} target='_blank' className={
                                                    sc.type === "facebook" ? "fab fa-facebook-square" :
                                                    sc.type === "instagram" ? "fab fa-instagram" :
                                                    sc.type === "twitter" ? "fab fa-twitter-square" :
                                                    sc.type === "youtube" ? "fab fa-youtube" :
                                                    sc.type === "whatsapp" ? "fab fa-whatsapp" :
                                                    "fab fa-facebook-square"
                                        }></a>
                                    </li>
                                )}
							</ul>
						</div>
					</div>
				</div>}
				{items && <div className="col-lg-2 col-md-4 offset-lg-1 text-center text-md-right">
					<div className="wgs mtm-8">
						<div className="wgs-content tc-light">
							<ul className="wgs-menu">
                                {items.map((it, index) =>
								    <li key={index}><NavLink to={it.value}>{it.title}</NavLink></li>
                                )}
							</ul>
						</div>
					</div>
				</div>}
			</div>
		</div>
	</footer>
  )
}

export default Footer