import React, { useState } from 'react'
import WorkerModal from '../modals/WorkerModal'

const WorkerCard = ({card}) => {
    const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="col-lg-3 col-sm-6">
        <div className="team-single text-center bg-bunker-alt tc-bunker h-full" onClick={() => setModalOpen(true)}>
            <div className="team-image">
                <img src={'https://solution-0004.panama.kz' + card.image} alt=""/>
                <a href="#team-popup-1" className="team-show content-popup"></a>
            </div>
            <div className="team-content team-content-s2-alt">
                <h5 className="team-name">{card.name}</h5>
                <p>{card.position}</p>
            </div>
        </div>
        {modalOpen && <WorkerModal show={modalOpen} setShow={setModalOpen} card={card}/>}
    </div>
  )
}

export default WorkerCard