import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';

const AdvertisingBanner = ({elem}) => {
    const [banner, setBanner] = useState();

    useEffect(() => {
        if(elem){
            setBanner(elem.elements[0])
        }
    }, [elem])
  return (
	<div className="section section-cta-alt cta-s3 tc-bunker has-bg-image">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-sm-10 col-md-8 text-center">
					{banner && <div className="cta-box">
						<h3 className="cta-heading">{banner.title}</h3>
						<p className="lead">{banner.description}</p>
						{banner.link && <NavLink href={banner.link} className="btn">{banner.subtitle}</NavLink>}
					</div>}
				</div>
			</div>
		</div>
		<div className="bg-image overlay-gradient-full overlay-fall bg-image-loaded" style={{backgroundImage: `url(https://solution-0004.panama.kz${banner?.image})`}}></div>
	</div>
  )
}

export default AdvertisingBanner