import React from 'react'
import Fancybox from './FancyBox'

const Gallery = ({elem}) => {
  return (
	<div className="section section-x section-project pb-0 tc-grey-alt" id="project">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-xl-7 text-center">
					<div className="section-head">
						{elem.subtitle && <h5 className="heading-xs dash dash-both">{elem.subtitle}</h5>}
						<h2>{elem.title}</h2>
					</div>
				</div>
			</div>
		</div>

		<Fancybox className="project-area bg-secondary"
			options={{
				Carousel: {
				infinite: false,
				},
			}}
		>
			<div className="row project project-v5 no-gutters" id="project1">
					{elem.elements?.map((card, index) =>
						<a data-fancybox="gallery" href={'https://solution-0004.panama.kz'+card} className="col-sm-6 col-md-4 col-xl-3 filtr-item" key={index}>
							<div className="project-item">
								<div className="project-image">
									<img src={'https://solution-0004.panama.kz'+card} alt="" />
								</div>
							</div>
						</a>
					)}
			</div>
		</Fancybox>
	</div>
  )
}

export default Gallery