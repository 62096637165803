import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Utils from '../../services/utils';
import AppServices from '../../services/general/appServices';

const ApplicationModal = ({show, setShow}) => {
    const handleClose = () => setShow(false);
    const {localizationsItems, lang} = useLangContext();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [success, setSuccess] = useState();

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {...data}
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        const res = await AppServices.requestTemplate(newData);

        if(res.statusCode === 200){
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
        }else{
            console.log(res)
            setSuccess(true)
        }
        setLoading(false)
    }

  return (
    <Modal show={show} onHide={handleClose} centered  size='lg'>
        <Modal.Body className='application-modal bg-bunker-alt'>
            <div title="Close" className="mfp-close" onClick={handleClose}>×</div>
            <form className="genox-form form-dark" onSubmit={handleSubmit(CustomSubmit)}>
                <h3 className="text-center">{localizationsItems?.modal_title}</h3>
                <p className='text-center'>{localizationsItems?.modal_content}</p>
				<div className="form-field">
					<input type="text" placeholder={localizationsItems?.name} className="input bdr-b required"/>
				</div>
				<div className="form-field">
					<input type="email" placeholder={localizationsItems?.email} className="input bdr-b required"/>
				</div>
				<div className="form-field">
					<input type="text" placeholder={localizationsItems?.phone} className="input bdr-b required"/>
				</div>
				<div className="form-field">
					<input type="text" placeholder={localizationsItems?.message} className="input bdr-b required"/>
				</div>
                {success && <p className='red'>{localizationsItems?.modal_fail}</p>}
				<div className="row justify-content-end">
					<div className="form-field col-md-auto">
						<input type="text" className="d-none" name="form-anti-honeypot" value=""/>
						<button type="submit" className="btn">{localizationsItems?.modal_button}</button>
					</div>
				</div>
			</form>
            <div className={visible ? 'form-result form-visible' : 'form-result'}>
                <h2>{localizationsItems?.modal_thanks}</h2>
                <p>{localizationsItems?.modal_success_content}</p>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default ApplicationModal