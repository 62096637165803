import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import EventsServices from '../../services/general/eventsServices';
import Pagination from './Pagination';
import EventCard from '../simple/EventCard';

const EventsContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(EventsServices.index)

  return (
    <div className="section section-news tc-grey pt-0">
        <div className="container">
            <div className="row gutter-vr-30px justify-content-sm-center">
                {items?.items?.map((card, index) =>
                    <EventCard card={card} key={index}/>
                )}
                <div className="col-md-8 order-md-last">
					<div className="button-area pagination-area">
                        <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
					</div>
				</div>
            </div>
        </div>
    </div>
  )
}

export default EventsContainer