import React, { useEffect, useState } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import ApplicationModal from '../modals/ApplicationModal';
import HeaderSearch from '../simple/HeaderSearch';
import MenuItem from '../simple/MenuItem';

const Header = ({item}) => {
    const [logo, setLogo] = useState();
    const {lang, localizationsItems} = useLangContext();
    const [modalOpen, setModalOpen] = useState();
    const [searchShow, setSearchShow] = useState();
    const [mobileShow, setMobileShow] = useState();
    const [sticky, setSticky] = useState();

    const {
        items,
    } = useFetchItems(AppServices.menu, 'main-menu')

    useEffect(() => {
        if(item){
            setLogo(item.find(it => it.type === "logo")?.value);
        }
    }, [item])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 100){
            setSticky(true)
        }else{
            setSticky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

  return (
	<header className={sticky ? "is-transparent header-s2 is-sticky is-shrink has-fixed" : "is-transparent header-s2 is-sticky is-shrink"} id="header">
		<div className="header-main">
			<div className="header-container container">
				<div className="header-wrap">
					<div className="header-logo logo">
						<NavLink to={`/${lang}`} className="logo-link">
							<img className="logo-light" src={'https://solution-0004.panama.kz' + logo} alt="logo"/>
						</NavLink>
					</div>
					<div className="header-nav-toggle">
						<div className="search search-mobile search-trigger" onClick={() => setSearchShow(true)}><i className="icon ti-search "></i></div>
						<div className={mobileShow ? "navbar-toggle active" : "navbar-toggle"} onClick={() => setMobileShow(!mobileShow)}>
							<div className="toggle-line">
								<span></span>
							</div>
						</div>
					</div>
					<div className={mobileShow ? "header-navbar menu-mobile menu-shown" : "header-navbar"}>
						<nav className="header-menu" id="header-menu">
							<ul className="menu">
                                {items?.map((card, index) =>
                                    <MenuItem card={card} key={index} setMobileShow={setMobileShow}/>
                                )}
							</ul>
							<ul className="menu-btns">
								<li><div className="btn search search-trigger" onClick={() => setSearchShow(true)}><i className="icon ti-search "></i></div></li>
								<li><button className="btn btn-sm" onClick={() => setModalOpen(true)}>{localizationsItems?.header_button}</button></li>
							</ul>
						</nav>
					</div>
                    {mobileShow && <div className='header-wrapper' onClick={()=>setMobileShow(false)}></div>}
                    <HeaderSearch searchShow={searchShow} setSearchShow={setSearchShow}/>
				</div>
			</div>
		</div>
        {modalOpen && <ApplicationModal show={modalOpen} setShow={setModalOpen}/>}
	</header>
  )
}

export default Header