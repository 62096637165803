import React from 'react'
import WorkerCard from '../simple/WorkerCard'

const Team = ({elem}) => {
  return (
	<div className="section section-xx section-xbl team mtm-30">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6 text-center">
					<div className="section-head section-md tc-light">
						<h5 className="heading-xs no-line tc-primary">{elem.subtitle}</h5>
						<h2 className="fz-1">{elem.title}</h2>
					</div>
				</div>
			</div>
			<div className="row justify-content-center gutter-vr-30px">
                {elem?.elements?.map((card, index) =>
                    <WorkerCard card={card} key={index}/>
                )}
			</div>
		</div>
	</div>
  )
}

export default Team