import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ProjectServices from '../../services/general/projectServices'
import Pagination from './Pagination'
import ProjectCard from '../simple/ProjectCard'

const ProjectsContainer = () => {
    
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ProjectServices.index)

  return (
	<div className="section section-project tc-light p-0" id="project">
		<div className="container">
			<div className="project-wrapper">
				<div className="project gutter-30px gutter-vr-30px justify-content-center row" id="project1">
					{items?.items?.map((card, index) =>
                        <ProjectCard card={card} key={index}/>
                    )}
                    <div className="col-md-8 order-md-last">
                        <div className="button-area pagination-area">
                            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default ProjectsContainer