import React from 'react'
import ArticleServices from '../../services/general/articleServices'
import useFetchItems from '../../hooks/useFetchItems'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const RecentNews = () => {
    const {lang, localizationsItems} = useLangContext();
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)
  return (
    <div className="wgs wgs-sidebar bg-bunker-alt wgs-recents wgs-recents-s2">
        <h3 className="wgs-heading">{localizationsItems?.recent_news}</h3>
        <div className="wgs-content">
            <ul className="post-recent">
                {items?.items?.slice(0,3).map((card, index) =>
                    <li key={index}>
                        <h5><NavLink to={`/${lang}/articles/${card.slug}`}>{card.title}</NavLink></h5>
                        <p className="post-tag">{card.date}</p>
                    </li>
                )}
            </ul>
        </div>
    </div>
  )
}

export default RecentNews