import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang'
import { useState } from 'react';
import ApplicationModal from '../modals/ApplicationModal';

const Pricing = ({elem}) => {
    const {localizationsItems} = useLangContext();
    const [modalOpen, setModalOpen] = useState(false);

  return (
	<div className="section section-x tc-bunker pt-0 has-bg-image" id="pricing">
		<div className="container container-lg-custom">
			<div className="row justify-content-center gutter-vr-30px text-center">
                {elem.elements?.map((card, index) =>
                    <div className="col-lg-4 col-sm-12" key={index}>
                        <div className="pricing-boxed pricing-boxed-s5 bg-bunker-alt">
                            <div className="pricing-price">
                                <h3><span className="price-unit">{card.type}</span>{card.price}<span className="price-for">{card.date}</span></h3>
                            </div>
                            <div className="pricing-feature" dangerouslySetInnerHTML={{__html: card.description}}></div>
                            <div className="pricing-cta pricing-cta-lg mt-auto">
                                <button className="btn btn-lg-s2 btn-capitalize ovm-w-100" onClick={() => setModalOpen(true)}>{localizationsItems?.pricing_button}</button>
                            </div>
                        </div>
                    </div>
                )}
			</div>
		</div>
		<div className="bg-image overlay-gradient-full overlay-fall bg-image-loaded" style={{backgroundImage: `url(https://solution-0004.panama.kz${elem?.review_image})`}}></div>
        {modalOpen && <ApplicationModal show={modalOpen} setShow={setModalOpen}/>}
    </div>
  )
}

export default Pricing