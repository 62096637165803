import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ProjectServices from '../../services/general/projectServices'
import WorkCard from '../simple/WorkCard'

const Portfolio = ({elem}) => {
    const {
        items,
        loading,
        setFilterData
    } = useFetchItems(ProjectServices.index)

  return (
	<div className="section section-project tc-light p-0" id="project">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-lg-8 text-center">
					<div className="section-head">
						<h5 className="heading-xs tc-primary">{elem.subtitle}</h5>
						<h2 className="fz-1">{elem.title}</h2>
					</div>
				</div>
			</div>
		</div>
		<div className="container">
			<div className="project-wrapper">
				<div className="project project-s4 gutter-30px gutter-vr-30px justify-content-center justify-content-md-start" id="project1">
                    {items?.items?.map((card, index) =>
                        <WorkCard card={card} key={index}/>
                    )}
				</div>
			</div>
		</div>
	</div>
  )
}

export default Portfolio