import React, { useState } from "react";
import { Collapse } from "react-bootstrap";

const CustomCollapse = ({elem}) => {
    const [open, setOpen] = useState();

  return (
        <div className="faq-item faq-item-s1 bg-bunker-alt">
            <h4 className="faq-title" onClick={() => setOpen(!open)}>
                {elem.title}<span className="faq-icon"></span>
            </h4>
            <Collapse in={open}>
                <div id="example-collapse-text">
                    <div className="faq-content" dangerouslySetInnerHTML={{__html: elem.description}}></div>
                </div>
            </Collapse>
        </div>
  );
};

export default CustomCollapse;
