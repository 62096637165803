import React from 'react'

const About = ({elem}) => {
  return (
	<div className="section section-xx tc-bunker" id="about">
		<div className="container">
			<div className="row gutter-vr-30px justify-content-around">
				<div className="col-lg-5 col-xl-4 text-center text-lg-left">
					<div className="text-box mrm-10">
						<h2 className="fz-1 mb-0">{elem.title}</h2>
					</div>
				</div>
				<div className="col-lg-6 col-xl-5 text-center text-lg-left">
					<div className="text-box mrm-20">
						<p className="lead"
                            dangerouslySetInnerHTML={{__html: elem.description}}
                        />
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default About