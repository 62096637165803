import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ServiceServices from '../../services/general/serviceServices'
import ServiceCard from '../simple/ServiceCard'
import Pagination from './Pagination'

const ServicesContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ServiceServices.index)

  return (
    <div className="section section-news tc-grey pt-0">
        <div className="container">
            <div className="row gutter-vr-30px justify-content-sm-center">
                {items?.items?.map((card, index) =>
                    <ServiceCard card={card} key={index}/>
                )}
                <div className="col-md-8 order-md-last">
					<div className="button-area pagination-area">
                        <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
					</div>
				</div>
            </div>
        </div>
    </div>
  )
}

export default ServicesContainer